import atk from './atk';
import cco from './cco';
import cio from './cio';
import comments from './comments';
import play from './play';

const index = {
  atk,
  cco,
  cio,
  comments,
  play,
  bookAd: {
    introText: 'BONUS TIP',
    bookTitle(title) {
      return `Get more tips in <span class="inline-book-ad__title">${title}</span>.`;
    },
  },
  bookQuickView: {
    browseAll(documentCount) {
      return `Browse all ${documentCount} recipes online`;
    },
    relatedsHeader: 'Cookbook Highlights',
  },
  cookingMode: {
    button: 'enter cooking mode',
  },
  courseAd: {
    label: 'COOKING SCHOOL',
    introText: 'TRY THIS RECOMMENDED COOKING COURSE',
  },
  detailPageFooterTitle: 'Recommended Reading',
  documentTypes: {
    buying_guide: 'Buying Guide',
    episode: 'Episode',
    equipment_review: 'Equipment Review',
    how_to: 'How To',
    kids_activity: 'Activity',
    kids_quiz: 'Quiz',
    kids_recipe: 'Recipe',
    recipe: 'Recipe',
    taste_test: 'Taste Test',
    video: 'Video',
  },
  episodes: {
    inThisEpisode: 'In this episode',
    season: 'SEASON',
  },
  equipmentReviews: {
    buyTheWinner: 'Buy the Winner',
  },
  favoriteActionUpsell: {
    cta: 'Start Free Trial',
    setup: 'To Unlock, Upgrade to All Access.',
    summary:
      'Save and organize recipes, reviews, and videos for easy access when cooking, meal planning, and shopping.',
    title: 'Start Saving Your Favorites',
  },
  landingPage: {
    whatGet: 'what do i get?',
    chooseProgram: 'pick your program',
    whoFor: "who's it for?",
    howDifferent: "how we're different",
    kidsSay: 'what kids are saying',
    faq: 'frequently asked questions',
    pickProgram: 'pick your program',
    weShip: 'we ship to you',
    timeToPlay: 'time to play',
    whatYouGet: 'what you get',
    whyATKKids: "why america's test kitchen kids?",
    whatElse: 'award-winning books and gear',
    team: 'meet the team',
    aboutUs: 'about us',
  },
  podcastApps: {
    proof: [
      {
        type: 'applePodcasts',
        name: 'Apple Podcasts',
        href: 'https://itunes.apple.com/us/podcast/proof/id1438546054',
      },
      {
        type: 'stitcher',
        name: 'Stitcher',
        href: 'https://www.stitcher.com/podcast/americas-test-kitchen-2/proof-2',
      },
      {
        type: 'spotify',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/0LgmhfcOQJNHyz6KyhtsDY?si=Egcx7XxbQou4mEDndA-3Gw',
      },
      {
        type: 'radioPublic',
        name: 'Radio Public',
        href: 'https://radiopublic.com/proof-Ww0OAm',
      },
      {
        type: 'tuneIn',
        name: 'TuneIn',
        href: 'https://tunein.com/podcasts/Food--Cooking-Podcasts/Proof-p1163135/',
      },
      {
        type: 'pocketCasts',
        name: 'Pocket Casts',
        href: 'https://pca.st/JFtm',
      },
      {
        type: 'castbox',
        name: 'Castbox',
        href: 'https://castbox.fm/x/_eDM',
      },
    ],
    mysteryRecipe: [
      {
        type: 'applePodcasts',
        name: 'Apple Podcasts',
        href: 'https://podcasts.apple.com/us/podcast/mystery-recipe/id1498382420',
      },
      {
        type: 'stitcher',
        name: 'Stitcher',
        href: 'https://www.stitcher.com/podcast/atk/mystery-recipe',
      },
      {
        type: 'spotify',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/2zy4jOJUPQt1MLeRjFki6U',
      },
      {
        type: 'radioPublic',
        name: 'Radio Public',
        href: 'https://radiopublic.com/mystery-recipe-6Nqvzg/s1!e1271',
      },
      {
        type: 'tuneIn',
        name: 'TuneIn',
        href: 'https://tunein.com/podcasts/Kids--Family-Podcasts/Mystery-Recipe-p1292788/',
      },
      {
        type: 'pocketCasts',
        name: 'Pocket Casts',
        href: 'https://pca.st/279yvp26',
      },
      {
        type: 'castbox',
        name: 'Castbox',
        href: 'https://castbox.fm/channel/Mystery-Recipe-id2624853',
      },
    ],
    walkIn: [
      {
        type: 'applePodcasts',
        name: 'Apple Podcasts',
        href: 'https://podcasts.apple.com/us/podcast/the-walk-in/id1523664707',
      },
      {
        type: 'stitcher',
        name: 'Stitcher',
        href: 'https://www.stitcher.com/podcast/atk/the-walkin',
      },
      {
        type: 'spotify',
        name: 'Spotify',
        href: 'https://open.spotify.com/show/158s8jp3H2gSlc22WIW6pv',
      },
      {
        type: 'radioPublic',
        name: 'Radio Public',
        href: 'https://radiopublic.com/the-walkin-WkbbjQ',
      },
      {
        type: 'tuneIn',
        name: 'TuneIn',
        href: 'https://tunein.com/podcasts/Food--Cooking-Podcasts/The-Walk-In-p1346421/',
      },
      {
        type: 'pocketCasts',
        name: 'Pocket Casts',
        href: 'https://pca.st/fbsnh7zh',
      },
      {
        type: 'castbox',
        name: 'Castbox',
        href: 'https://castbox.fm/channel/The-Walk-In-id3116048',
      },
    ],
  },
  print: {
    fontSize:
      'Adjust oven rack to middle position and heat oven to 350 degrees.',
    labels: {
      buttons: 'PRINT WITH:',
      fontSize: 'CHANGE TEXT SIZE:',
    },
    options: {
      asideGroupsContent: 'TECHNIQUES',
      ingredientGroups: 'INGREDIENTS',
      instructions: 'INSTRUCTIONS',
      keyEquipment: 'KEY EQUIPMENT',
      nutritionSummary: 'NUTRITIONAL INFORMATION',
      recipePhoto: 'RECIPE PHOTO',
      stepPhotos: 'STEP PHOTOS',
      whyThisWorks: 'WHY THIS RECIPE WORKS',
    },
    registration: {
      heading: 'Nice choice.',
      pre: 'And we’re print people, too. Want to get every recipe, rating, and episode?',
      cta: 'Sign up',
      post: 'for a 14-day FREE trial to unlock everything on our sites.',
    },
    relateds: 'RECIPE PAIRINGS',
  },
  recipes: {
    bookAlternatesLabel(count) {
      const s = count > 1 ? 's' : '';
      return `This recipe also appears in the following cookbook${s}: `;
    },
    bookAttributionLabel: 'Appears in',
    bookBrowseAll: 'Browse all recipes',
    bookDocumentCount(count) {
      return `Browse all ${count.toLocaleString()} recipes`;
    },
    freedUpCta: {
      description:
        'Love this recipe? Members get every recipe, rating, and video. ',
      cta: 'Go All Access',
    },
    fromCookbook: 'A cookbook recipe exclusively for All-Access members from ',
    headnoteTitle: 'BEFORE YOU BEGIN',
    ingredients: 'INGREDIENTS',
    ingredientsTitle: 'GATHER YOUR INGREDIENTS',
    instructions: 'INSTRUCTIONS',
    keyEquipment: 'KEY EQUIPMENT',
    magazineAd: {
      cco: {
        segments: {
          registrant: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=189767&cds_response_key=IKR19ERA0',
            text: 'Claim your free trial issue.',
          },
          anonymous: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=189767&cds_response_key=IKR19EAA0',
            text: 'Claim your free trial issue.',
          },
          member: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=235280&cds_response_key=IKR19ES10',
            text: 'Subscribe at your exclusive member price.',
          },
          cancelled: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=189153&cds_response_key=IKR19EE1E',
            text: 'Subscribe today and get a free gift.',
          },
        },
        text: 'In every issue, Cook’s Country serves up fresh ideas for everyday cooking. ',
      },
      cio: {
        segments: {
          registrant: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=186996&cds_response_key=ICR19JRAB',
            text: 'Subscribe today and get a free gift.',
          },
          anonymous: {
            bodyText:
              'Every issue contains reliable recipes that deliver confidence. ',
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=198211&cds_response_key=ICR19JAA0',
            text: 'Get a free trial issue.',
          },
          member: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=235180&cds_response_key=ICR19JH10',
            text: 'Subscribe at your exclusive member price.',
          },
          cancelled: {
            href: 'https://w1.buysub.com/servlet/OrdersGateway?cds_mag_code=CID&cds_page_id=186996&cds_response_key=ICR19JEAB',
            text: 'Subscribe today and get a free gift.',
          },
        },
        text: 'Have you seen our new full-color look? ',
      },
      title: 'Recipe developed for the',
      issue: 'issue.',
    },
    nutritionQuickView: {
      calories: 'Calories',
      edamam:
        'The information shown is Edamam’s estimate based on available ingredients and preparation. It should not be considered a substitute for a professional nutritionist’s advice.',
      title: 'Nutritional Information',
      yield: (val) => `Per Serving (Serves ${val})`,
    },
    nutritionalInformation: 'View Nutritional Information',
    recommendedReading: 'Recommended Reading',
    servingSize: 'SERVES',
    socialShare: {
      shareThe: 'Share the',
      recipe: 'Recipe',
      before: 'We hope you liked this recipe! ',
      button: ' Share it',
      after:
        ', or leave a rating and comment below. (Our test kitchen staff might even jump in! But for questions that need an answer from us, please ',
      link: 'mailto:cooks@americastestkitchen.com',
      linkText: ' contact',
      afterLink: ' our Customer Support team.)',
    },
    time: 'TIME',
    why: 'WHY THIS RECIPE WORKS',
  },
  episode: {
    socialShare: {
      shareThe: 'Share the',
      recipe: 'Recipe',
      before: 'Like this video? ',
      button: 'Share it',
      after: '',
    },
  },
  retention: {
    favorites:
      'Did you know? Your membership includes the all new <strong>ATK App</strong> with every recipe and rating, weekly recipe collections, your Favorites and our Shopping List tool. <a href="https://www.americastestkitchen.com/guides/digital-apps/mobile-apps" data-type="app">Learn more&nbsp;<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16" width="12"><path d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"/></svg></a>',
    skills:
      'Did you know? Your membership includes <strong>new Skills videos</strong> from our test cooks and TV hosts that demo techniques for building flavor, saving time, or both. <a href="https://www.americastestkitchen.com/skills" data-type="skills">Watch now&nbsp;<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16" width="12"><path d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"/></svg></a>',
    cookbook:
      'Did you know? Your membership includes access to our <strong>Cookbook Collection</strong> with new recipes from our best-selling cookbooks added every month. <a href="https://www.americastestkitchen.com/books" data-type="cookbook">Browse now&nbsp;<svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 16 16" width="12"><path d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"/></svg></a>',
    ratings:
      'Did you know? <strong>Recipe ratings</strong> are here! Members can now rate recipes (from one to five stars) and see what other members think. Rate your favorites now.',
  },
  reviewset: {
    articleOverviewHeading: 'What You Need To Know',
    socialShare: {
      shareThe: 'Share the',
      recipe: 'Review',
      before: 'Like this review? ',
      button: 'Share it',
      after: ', or leave a comment below.',
    },
  },
  registration: {
    form: {
      email: {
        error: 'Please enter a valid email.',
        placeholder: 'Enter your email address',
      },
      password: {
        error: 'Your password must be at least 5 characters.',
        placeholder: 'Create a password',
      },
      errors: {
        default: 'Oops, something bad happend. Please try again later',
        400: 'Please check the email address and password you submitted.',
        409: 'It looks like you already have an account on one of our sites.',
      },
    },
    howWeUseText: `
      America’s Test Kitchen will not sell, rent, or disclose your email
      address to third parties unless otherwise notified.
      Your email address is required to identify you for free access to
      content on the site. You will also receive free newsletters and
      notification of America’s Test Kitchen specials.
    `,
    print: {
      heading: 'Print it your way with a free account',
      benefitsList: [
        'Resize text for easier reading',
        'Exclude photos to save paper',
        'Print your shopping list',
      ],
    },
    save: {
      heading: 'Save your Favorites with a free account',
      benefitsList: [
        'Made or want to make? Save it!',
        'Organize how you want',
        'Spend time cooking, not “re-searching”',
      ],
    },
    saveConfirmation: {
      heading: 'Smart and organized, that’s you. Don’t stop now.',
      description: 'Get ALL the recipes, ratings & clever how-tos.',
      cta: 'Sign Up',
      ctaDescription:
        'for a 14-day FREE trial to unlock everything on our sites.',
    },
  },
  relatedDocumentCta: {
    recipe: 'get the recipe',
    taste_test: 'get the taste test',
    equipment_review: 'get the review',
  },
  relatedDocumentTitle: 'More From',
  tagListTitle: 'Keep Browsing',
  relatedArticleTitle: 'Article',
  relatedEpisodeTitle: 'Episode',
};

export default index;
