import React from 'react';
import PropTypes from 'prop-types';

const GTagReportConversion = ({ id }) => (
  <script
    key="gtag-report-conversion"
    dangerouslySetInnerHTML={{
      __html: `
        function gtagReportConversion(url) {
          gtag('event', 'conversion', { 'send_to': '${id}' });
          return false;
        }
      `,
    }}
  />
);

GTagReportConversion.propTypes = {
  id: PropTypes.string.isRequired,
};

export default GTagReportConversion;
