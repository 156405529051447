const comments = {
  clearPhotos: 'Clear Photos',
  count(total = 0) {
    return `${total.toString()} Comment${total === 1 ? '' : 's'}`;
  },
  loadMore: 'Load more comments',
  placeholder: 'Join the discussion...',
  sort: {
    best: 'Most Liked',
    newest: 'Newest',
    oldest: 'Oldest',
  },
  filter: {
    all: 'All',
    verified: 'ATK Replies',
  },
  submit: 'Post',
  upload: {
    default: 'upload up to 3 images',
    recipe: 'Upload up to 3 images',
    multiple: 'add another image',
  },
  flaggedUrl:
    'https://www.americastestkitchen.com/guides/corporate-pages/posting-guidelines',
  emptyState: {
    noAtkReplies: {
      header: 'No ATK Replies',
      body: 'This recipe doesn’t have any test cook replies yet. Add a comment / ask a question and get the conversation going!',
    },
    noComments: {
      header: 'No Comments',
      body: 'This recipe doesn’t have any comments yet. Be the first to add a comment / ask a question and get the conversation going!',
    },
  },
  userActions: {
    report: 'Report',
    edit: 'Edit',
    delete: 'Delete',
  },
  widgetType: {
    comment: 'upload_widget_comment',
    reply: 'upload_widget_reply',
    edit: 'upload_widget_edit',
  },
  modal: {
    cancel: {
      header: 'Discard Edits',
      content:
        'You’ve made edits to your comment. Are you sure you want to discard them?',
      button: 'Discard',
    },
    delete: {
      header: 'Delete Comment',
      content: 'Are you sure you want to delete your comment?',
      button: 'Delete',
    },
    report: {
      header: 'Report Comment',
      content: 'Are you sure you want to flag this comment as inappropriate?',
      button: 'Report',
    },
  },
};

export default comments;
