import React, { Component } from 'react';
import PropTypes from 'prop-types';

import utils from '../../../lib/utils';
import { userParams } from '../../../lib/analytics/google';

class GoogleTagDataLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    utils.getUserData(this.updateState.bind(this));
  }

  updateState(user) {
    this.setState(user);
  }

  renderMarkup() {
    const { user } = this.state;
    const { queryParams } = this.props;
    const dataLayer = [userParams(user, queryParams)];

    const createMarkup = (dataLayerInit) => {
      /* eslint-disable */
      const markup = `
        var dataLayer = ${JSON.stringify(dataLayerInit)}, GTM;
        GTM = {
          // Allows us to send only data that's changed since page load
          push: function(data) {
            var copy = dataLayer.slice()[0];
            dataLayer.push(Object.assign(copy, data));
          }
        };
      `;
      /* eslint-enable */
      return {
        __html: markup,
      };
    };
    return (
      <script
        id="google-tag-data-layer"
        dangerouslySetInnerHTML={createMarkup(dataLayer)}
      />
    );
  }

  render() {
    return this.renderMarkup();
  }
}

GoogleTagDataLayer.propTypes = {
  queryParams: PropTypes.object,
};

GoogleTagDataLayer.defaultProps = {
  queryParams: null,
};

export default GoogleTagDataLayer;
