import React from 'react';

export default class GoogleAnalytics extends React.Component {
  render() {
    const createMarkup = () => {
    /* eslint-disable */
      const markup = `
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('set','dimension1','control');
      ga('set','dimension2',window.Yo?'optimized' : 'control');
      `;
      /* eslint-enable */
      return {
        __html: markup,
      };
    };

    return (
      <script
        className="google-analytics"
        dangerouslySetInnerHTML={createMarkup()}
      />
    );
  }
}
