import { getSiteCapitalized } from 'lib/utils';

const atk = {
  anniversary: {
    adAltText:
      "Book cover for America's Test Kitchen 20th Anniversary Special TV Show Cookbook",
    adCta: 'Order Now - Save $15',
    adText: "Get America's Most-Tested and Best-Loved Recipes",
    descriptionTitle: 'Description',
    bookAdTitle:
      'Twenty years ago we stepped into the role of TV test cooks . . .',
    bookAdPOne:
      'We could never have predicted the positive impact the show would have on millions of people. It all comes down to a deceptively simple mission that remains unique: We tear apart recipes, test each variable, and then build foolproof versions.',
    bookAdPTwoBold:
      'Our TV Special counts down our top recipes from over 500 shows and this new cookbook includes 580 recipes that have shaped the way America cooks. ',
    bookAdPTwoCta: 'Order your copy today',
    bookAdPTwoEnd: ', and let the celebrations, and great cooking, begin.',
    bookAdCta: 'Save $15 Now',
  },
  articleDetailPageKids: {
    hero: {
      cta: 'SIGN ME UP',
      description:
        'The best of America’s Test Kitchen Kids delivered to your inbox.',
      howText: 'How we use your email address',
      placeholder: 'Your email address',
      heading: 'Get FREE recipes & activities for kids!',
      heroImages: {
        mobile: 'ATK%20Kids/Kids%20Marketing%20Hat/smoothies_mobile',
        tablet: 'ATK%20Kids/Kids%20Marketing%20Hat/smoothies_tablet',
        desktop: 'ATK%20Kids/Kids%20Marketing%20Hat/smoothies_desktop',
      },
    },
  },
  dfp: {
    ATK_Recipe_Detail_small_rectangle_1: {
      text: 'We are thankful to the sponsors who make it possible for us to bring you the America\'s Test Kitchen TV series on public television. <a href="/sponsors">Read more about why we have sponsors.</a>',
      title: 'FROM OUR TV SPONSORS',
    },
  },
  meta: {
    title: "America's Test Kitchen",
  },
  relatedDocumentTitle: "More From America's Test Kitchen",
  anonHomePage: {
    headingOne: "America's Test Kitchen",
    bookAd: {
      cta: 'SAVE 56%',
      description: 'Get 1,670+ recipes from all 21 seasons!',
      heading: 'SPECIAL OFFER',
    },
    faq: {
      heading: 'Frequently Asked Questions',
      questions: [
        {
          answer:
            'All of our recipes have been thoroughly researched and rigorously tested (up to 100 times!) by our dedicated team of test cooks and food scientists. We examine and tweak every variable—from ingredients, prep techniques, and equipment to the cooking method, time, and temperature—until we find the best tasting, most reliable version. Then we send recipes to over 70,000 volunteer recipe testers who offer feedback on instructions and results. We make final tweaks based on their feedback before the recipe debuts on our sites, in our magazines or cookbooks, and on our TV shows.',
          key: 'homepage-faq-approved',
          question:
            'Your recipes are kitchen-tested and home-cook approved. What does that mean?',
        },
        {
          answer:
            'We empower home cooks with objective, honest, and reliable information so they can shop smart and cook with confidence. We accept no advertising and pay for every product we test. Our equipment reviews are based on our evaluation of product performance, design, and reliability. Our ingredient reviews are based on the results of blind taste tests of randomized samples. Tasters evaluate the flavor and texture of an ingredient plain and baked or cooked into our recipes. All results are shared with home cooks, and Best Buy options are noted when lower priced products rank highly in our tests.',
          key: 'homepage-faq-trust',
          question: 'Why should I trust your product reviews?',
        },
        {
          answer:
            'We work for home cooks. Period. Every recipe, episode, article, video, and review we publish is created with you in mind to give you the tools you need to become a better cook. No hidden agenda. We don’t accept advertising, so you’ll never have to worry about distracting offers from other companies while you’re browsing our sites. ',
          key: 'homepage-faq-different',
          question:
            'What makes your sites different from other online cooking sites?',
        },
        {
          answer:
            'Learn more at <a href="https://www.americastestkitchen.com/support#why-charge-for-services">Why charge for services</a>.',
          key: 'homepage-faq-learn-more',
          question:
            'Where can I find more information about All-Access Membership and the work you do?',
        },
      ],
    },
    hero: {
      cta: 'GET FREE ACCESS',
      description:
        'Start your 2-week free trial and get instant access to every recipe, review, and video across our sites.',
      howText: 'How we use your email address',
      placeholder: 'Your email address',
      heading: 'Cook Anything with Confidence',
    },
    benefits: {
      benefitsOne: [
        '<strong>12,000+ foolproof recipes</strong> on our America’s Test Kitchen, Cook’s Illustrated, and Cook’s Country sites; new recipes added every month',
        '<strong>675+ full episodes</strong> of our TV shows from all seasons',
        'Our complete library of <strong>how-to videos</strong>',
        'Unbiased equipment and ingredient <strong>reviews and ratings</strong>',
        '<strong>Free shipping</strong> in the ATK Shop and our Exclusive <strong>Member’s App</strong>',
      ],
      benefitsTwo: [
        '<strong>675+ full episodes</strong> of our TV shows from all seasons',
        '<strong>12,000+ foolproof recipes</strong> on our America’s Test Kitchen, Cook’s Illustrated, and Cook’s Country sites; new recipes added every month',
        '<strong>Free shipping</strong> in the ATK Shop and our Exclusive <strong>Member’s App</strong>',
        'Our complete library of <strong>how-to videos</strong>',
        'Unbiased equipment and ingredient <strong>reviews and ratings</strong>',
      ],
    },
    jackLetter: {
      footer:
        'And if you’re hungry for a good story, check out <a href="https://www.americastestkitchen.com/podcasts/the-walk-in">The Walk-In,</a> our new podcast featuring Elle Simone Scott.',
      heading: 'We’re helping 1.2 Million Home Cooks and Counting',
      introOne:
        'Our mission is to inspire home cooks by giving them the best recipes, resources, techniques, and tools so they can approach any recipe with confidence. Cooking discoveries are what we make. Sharing them is what we do. It’s as simple as that.',
      introTwo:
        'With over two decades on the air, our TV shows are still inspiring home cooks everywhere to roll up their sleeves and learn something new in the kitchen. We’ll continue to share what we know as long as you’ll have us. Prefer books over screens? Check out our new TV show cookbook.',
      listHeading: 'All-Access Membership gives you:',
      name: 'JACK BISHOP',
      title: 'CHIEF CREATIVE OFFICER',
    },
    brands: {
      atkDesc:
        'Empowering and inspiring confidence, community, and creativity in the kitchen.',
      ccoDesc:
        'Seeking out America’s best cooking ideas and creating easy-to-follow recipes.',
      cioDesc:
        'Teaching curious cooks through game-changing recipes and techniques.',
      kidsDesc:
        'Building a new generation of curious cooks and engaged eaters.',
    },
    testimonialHeading: 'Testimonials',
    testimonials: [
      {
        backgroundId: 'ako homepage anon/testimonial-background-one',
        bubble: 'Roasted Butternut Squash with Pistachios and Feta',
        heading: 'Support for',
        underlinedHeading: 'weeknight cooking',
        underlineId: 'testimonial-underline-one',
        quote:
          'It’s really an awesome resource for people like me who are trying to do the best they can.',
        userDate: 'Member since 2019',
        userName: 'Melissa C.',
        userPhoto: 'ako homepage anon/user-testimonial-one',
      },
      {
        backgroundId: 'ako homepage anon/testimonial-background-two',
        bubble: 'Tasting Fontina Cheese',
        heading: 'Help with',
        underlinedHeading: 'shopping',
        underlineId: 'testimonial-underline-two',
        quote:
          'I look at the product reviews all the time. When I’m in the grocery store, I’ll often pull up the app on my phone, “which fontina should I choose?!” ',
        userDate: 'Member since 2016',
        userName: 'Piper m.',
        userPhoto: 'ako homepage anon/user-testimonial-two',
      },
      {
        backgroundId: 'ako homepage anon/testimonial-background-three',
        bubble: 'Spiced Red Lentils Tofu Spinach',
        heading: 'Confidence to',
        underlinedHeading: 'try new recipes',
        underlineId: 'testimonial-underline-three',
        quote:
          'I’m more willing to experiment because I know the recipe will be well-written and will come out well.',
        userDate: 'Member since 2020',
        userName: 'Kelly b.',
        userPhoto: 'ako homepage anon/user-testimonial-three',
      },
      {
        backgroundId: 'ako homepage anon/testimonial-background-four',
        bubble: 'No Bake Apple Crisp',
        heading: 'Opportunities to',
        underlinedHeading: 'learn',
        underlineId: 'testimonial-underline-four',
        quote:
          'It’s cool to test a recipe and you see it come out in print you’re like OH MAN! And you can see the tweaks! That’s cool.',
        userDate: 'Member since 2017',
        userName: 'Nat b.',
        userPhoto: 'ako homepage anon/user-testimonial-four',
      },
      {
        backgroundId: 'ako homepage anon/testimonial-background-five',
        bubble: 'Garam Masala Pork Chop Couscous Spinach',
        heading: 'Recipes You',
        underlinedHeading: 'Can Count On',
        underlineId: 'testimonial-underline-five',
        quote:
          'I use ATK as my go-to source of truth for food because I know the recipes will be worthy of my dinner table. ',
        userDate: 'Member since 2019',
        userName: 'Cameron d.',
        userPhoto: 'ako homepage anon/user-testimonial-five',
      },
    ],
    valueProps: {
      app: {
        bubble: 'Organize your list by ingredient type or recipe.',
        heading: 'Exclusive Member’s App',
        desc: 'Your pocket guide to foolproof cooking. Browse recipes, reviews, and videos. Create shopping lists, edit favorites, and more.',
      },
      community: {
        heading: 'Community',
        desc: 'Swap recipe hacks and kitchen wins in our members-only facebook group and post comments on our sites. ',
        chats: [
          {
            header: 'Peer Support',
            messages: [
              {
                emojiName: 'thumb',
                emojiSrc: 'ako homepage anon/community-emoji-thumb',
                message:
                  'I made the Easy Chocolate Snack Cake from The Perfect Cake. It’s nothing fancy, but this sure is good!',
                recipePhoto: 'ako homepage anon/community-user-one-recipe',
                userName: 'CATHERINE',
                userPhoto: 'ako homepage anon/community-user-one-headshot',
              },
              {
                emojiName: 'heart',
                emojiSrc: 'ako homepage anon/community-emoji-heart',
                message: 'Shepard’s Pie and a nice Irish Stout!',
                recipePhoto: 'ako homepage anon/community-user-two-recipe',
                userName: 'JEFFERY',
                userPhoto: 'ako homepage anon/community-user-two-headshot',
              },
            ],
          },
          {
            header: 'Expert Answers',
            messages: [
              {
                flip: 'true',
                message:
                  'Why is it important to move on to step 3 “as soon as the dough is domed and bubbly”? What would happen if I did it too soon or waited too long? Thanks.',
                userInitials: 'DP',
                userName: 'DARREN P.',
              },
              {
                message:
                  'If you wait too long the dough will over-proof and you’ll end up with a flat loaf.',
                userName: 'ANDREA GEARY',
                userPhoto: 'ako homepage anon/community-user-four-headshot',
                userTitle: 'test cook',
              },
            ],
          },
        ],
      },
      recipes: {
        heading: 'Kitchen-Tested, Home-Cook Approved',
        desc: 'We analyze every variable of a recipe, testing ingredients, tools, techniques, cooking methods, etc. to understand how and why recipes work.',
        bonus: 'BONUS! ',
        bonusDesc:
          'New cookbook recipes are added every month from popular titles such as The Perfect Pie, Tasting Italy, and more.',
        cardGrid: [
          [
            {
              attribution: 'Fougasse',
              backgroundId: 'ako homepage anon/recipes-rect-one',
              shape: 'rect',
              siteKey: 'atk',
              textColor: 'eclipse',
              quote: {
                position: 'top',
                size: 'normal',
                text: '“As good as if not better than the local artisan bakery which sells these for $10 each.”',
              },
            },
            {
              attribution: 'Slow-Cooker Sausage Ragu',
              backgroundId: 'ako homepage anon/recipes-square-one',
              shape: 'square',
              siteKey: 'cco',
              textColor: 'eclipse',
            },
          ],
          [
            {
              attribution:
                'Sweet and Spicy Orange Chicken with Broccoli for Two',
              backgroundId: 'ako homepage anon/recipes-square-two',
              shape: 'square',
              siteKey: 'atk',
              textColor: 'white',
            },
            {
              backgroundId: 'ako homepage anon/recipes-rect-two',
              shape: 'rect',
              siteKey: 'cco',
              textColor: 'eclipse',
              quote: {
                position: 'bottom',
                size: 'normal',
                text: '“These are so much like the ones my grandmother used to make and I could never duplicate.”',
              },
              attribution: 'Thin and Crispy Chocolate Chip Cookies',
            },
          ],
          [
            {
              backgroundId: 'ako homepage anon/recipes-rect-three',
              shape: 'rect',
              siteKey: 'cio',
              textColor: 'white',
              quote: {
                position: 'top',
                size: 'large',
                text: '“Seriously, this cake is the BEST!”',
              },
              attribution: 'Yellow Sheet Cake with Chocolate Frosting',
            },
            {
              backgroundId: 'ako homepage anon/recipes-square-three',
              shape: 'square',
              siteKey: 'cio',
              textColor: 'white',
              attribution: 'Chicken Vesuvio',
            },
          ],
        ],
      },
      reviews: {
        heading: 'Bias-Free Product Reviews & Ratings',
        desc: 'Our reviews are based on firsthand experience of using the products. We purchase all equipment and ingredients  at retail cost (no freebies) and don’t accept advertising.',
        cardGrid: [
          {
            bubble: 'The biggest, heaviest, and best we tested',
            cards: [
              {
                attribution: 'Testing 12-Inch Ceramic Nonstick Skillets',
                backgroundId: 'ako homepage anon/reviews-rect-one',
                shape: 'rect',
                siteKey: 'atk',
                textColor: 'white',
              },
              {
                attribution: 'Supermarket Extra-Virgin Olive Oil',
                backgroundId: 'ako homepage anon/reviews-square-one',
                shape: 'square',
                siteKey: 'atk',
                textColor: 'white',
              },
            ],
          },
          {
            bubble: 'Beautifully tender without being mushy',
            cards: [
              {
                attribution: 'Tasting Brown and Green Lentils',
                backgroundId: 'ako homepage anon/reviews-square-two',
                shape: 'square',
                siteKey: 'atk',
                textColor: 'white',
              },
              {
                attribution: 'Testing Mortars and Pestles',
                backgroundId: 'ako homepage anon/reviews-rect-two',
                shape: 'rect',
                siteKey: 'atk',
                textColor: 'white',
              },
            ],
          },
          {
            cards: [
              {
                attribution: 'The Case for Angel Hair Pasta',
                backgroundId: 'ako homepage anon/reviews-rect-three',
                shape: 'rect',
                siteKey: 'atk',
                textColor: 'white',
              },
              {
                attribution: 'Testing Squeeze Bottles',
                backgroundId: 'ako homepage anon/reviews-square-three',
                shape: 'square',
                siteKey: 'atk',
                textColor: 'white',
              },
            ],
          },
        ],
      },
      videos: {
        heading: 'Videos & Podcasts',
        desc: 'Stay curious with our growing vault of TV episodes, how-to videos, and recipe tutorials that give you step-by-step cooking guidance.',
        platforms: 'Find us on these platforms',
        podcastsHeading: 'Go beyond food with podcasts',
        podcasts: [
          {
            alt: 'Mystery Recipe Podcast',
            cloudinaryId: 'ako homepage anon/mystery-recipe-logo',
            key: 'mystery-recipe-prop',
          },
          {
            alt: 'Proof Podcast',
            cloudinaryId: 'ako homepage anon/proof-logo',
            key: 'proof-prop',
          },
          {
            alt: 'The Walk-in',
            cloudinaryId: 'ako homepage anon/the-walk-in-logo',
            key: 'the-walk-in-prop',
          },
        ],
      },
    },
    membershipAd: {
      cta: 'GET FREE ACCESS',
      description:
        'Start your 2-week free trial and get instant access to every recipe, review, and video across our sites: ',
      heading: 'TRY ALL-ACCESS MEMBERSHIP FREE',
      howText: 'How we use your email address',
      placeholder: 'Your email address',
      subheading: 'Discover a Better Way to Cook.',
    },
  },
  reviewSets: {
    browse: {
      heading: 'Browse Reviews',
      faq: {
        description:
          'The mission of the ATK Reviews Team is to find the best equipment and ingredients for the home cook through rigorous, hands-on testing.',
        heading: 'Reviews you can trust',
        questions: [
          {
            answer:
              'We have a dedicated team of 10 full-time product reviewers and editors. In previous lives we were newspaper reporters, professional bakers, cookbook editors, farmers, bartenders, and restaurant cooks—and we even count an epidemiologist among us. We believe the right kitchen equipment and ingredients can make your life better.',
            key: 'browse-reviews-faq-who',
            question: 'Who exactly reviews all of these products?',
          },
          {
            answer:
              'We think (and shop) like home cooks. We purchase all of the products we test at retail, which allows us to remain impartial and objective. Our testing is careful and rigorous. Each review we publish is based on weeks or even months of hands-on testing. Once we know how a product performs, we want to make sure it will last. We test everything for durability, washing items repeatedly and even tossing them off the counter. We make a smoothie in our winning blender 365 times in a row or dip a piping hot skillet into a bucket of ice water to check for warping. We call on experts—scientists, professors, industry specialists, and our own science research editor—to help us understand our test results. When we recommend an item, you can trust that it’s been thoroughly and completely vetted.<br /><br />We don’t accept advertising or free products. Whenever possible, we provide links to products so that you can easily find our recommended items. When you make a purchase using one of those links, we may earn an affiliate commission.',
            key: 'browse-reviews-faq-why',
            question: 'Why should I trust you?',
          },
          {
            answer:
              'We focus on cooking equipment and ingredients but we also test products that are used outside the kitchen. We’ve evaluated camp stoves and coolers as well as robot vacuums and outdoor pizza ovens. To put together our testing lineups, we survey the market. Our goal is always to test the full range of options available. We include both big, familiar brands and products from newer, smaller companies. For all of our reviews, we focus on products that can be easily found at brick-and-mortar stores or online.<br/><br/>If you have a suggestion for a new review or product we should test, we would love to hear about it! Email us at <a href="mailto: atkreviews@americastestkitchen.com">atkreviews@americastestkitchen.com</a>.',
            key: 'browse-reviews-faq-how',
            question: 'How do you decide what to test?',
          },
          {
            get answer() {
              return `You’re starting in the right place! All of our equipment and ingredient reviews are right here on <a href="/">${getSiteCapitalized()}</a>. You can also find our reviews in each issue of <span className="faq__brand-title">Cook’s Illustrated</span> and <span className="faq__brand-title">Cook’s Country</span> as well as on the <span className="faq__brand-title">America’s Test Kitchen</span> and <span className="faq__brand-title">Cook’s Country </span>  television shows.`;
            },
            key: 'browse-reviews-faq-where',
            question: 'Where else can I find your reviews?',
          },
        ],
      },
    },
  },
};

export default atk;
