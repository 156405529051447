import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const GoogleSiteTag = ({ id }) => (
  <Fragment>
    <script defer src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
    <script
      key="google-site-tag"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments);}
          gtag('js', new Date());
        `,
      }}
    />
  </Fragment>
);

GoogleSiteTag.propTypes = {
  id: PropTypes.string.isRequired,
};

export default GoogleSiteTag;
