const cco = {
  dfp: {
    CCO_Recipe_Detail_small_rectangle_1: {
      text: 'We are thankful to the sponsors who make it possible for us to bring you the Cook’s Country TV series on public television. <a href="/sponsors">Read more about why we have sponsors.</a>',
      title: 'FROM OUR TV SPONSORS',
    },
  },
  meta: {
    title: "Cook's Country",
  },
  recipes: {
    servingSize: 'SERVES',
    why: 'WHY THIS RECIPE WORKS',
  },
  relatedDocumentTitle: "More From Cook's Country",
};

export default cco;
