const cio = {
  buying_guides: {
    aside: {
      content:
        '**Cook better and save money.** Every product in this collection has been evaluated by our team of experts in a rigorous review and earned one of our highest ratings. We never accept advertising or free products.',
      promoImage: null,
    },
  },
  dfp: {},
  meta: {
    title: "Cook's Illustrated",
  },
  relatedArticleLink: 'Want more? Read the whole story.',
  relatedDocumentTitle: "More From Cook's Illustrated",
};

export default cio;
